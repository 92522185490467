import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthGuard, AuthService } from '@auth0/auth0-angular';
import { EnvConfigService } from '@app/shared/service/env-config.service';
import { LOCATION_TOKEN } from '@app/shared/service/location-token';
import { QueryParamKeys } from '@app/shared/constants/query-param-keys';

@Injectable({
  providedIn: 'root'
})
export class Auth0Guard extends AuthGuard implements CanActivate, CanActivateChild {
  get isAuth0Enabled(): boolean {
    return !!this.envConfigService.getSettings()?.auth?.enabled;
  }

  constructor(
    auth: AuthService,
    private envConfigService: EnvConfigService,
    @Inject(LOCATION_TOKEN) private location: Location,
  ) {
    super(auth);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const queryParams = new URLSearchParams(this.location.search);
    const isGrandCentral = queryParams.get(QueryParamKeys.ref) === 'grand-central';

    if (this.isAuth0Enabled || isGrandCentral) {
      return super.canActivate(route, state);
    }

    return of(true);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const queryParams = new URLSearchParams(this.location.search);
    const isGrandCentral = queryParams.get(QueryParamKeys.ref) === 'grand-central';

    if (this.isAuth0Enabled || isGrandCentral) {
      return super.canActivateChild(childRoute, state);
    }

    return of(true);
  }
}
