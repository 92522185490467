import { getCacheTime, getParsedLocalStorageItemWithTtl } from '@app/utilities/local-storage-utils';
import { StorageKeys } from '@app/shared/constants/web-storage';
import { DEFAULT_TENANT_ID, TOP_LEVEL_APPLICATION } from '@app/shared/constants/common';
import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '@app/shared/model/app-config.model';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { TlaConfig } from '@app/shared/model/tla-config.model';
import { WINDOW_TOKEN } from '@app/shared/service/window-token';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DomainService {
  private brandingUrl: string;
  private tlaUrl: string;

  constructor(
    @Inject(WINDOW_TOKEN) private window: Window,
    private readonly http: HttpClient,
  ) {}

  getAuth0OrganizationId(appConfig: AppConfig): Observable<string> {
    this.brandingUrl = `${appConfig.brandingRoot}/config/domains.json`;
    this.tlaUrl = `${appConfig.invitationServiceUrl}/top-level-application-service/api/v1/top-level-application`;
    const localAuth0OrgId = getParsedLocalStorageItemWithTtl<string>(this.window.localStorage.getItem((StorageKeys.Auth0OrganizationId)));

    if (localAuth0OrgId) {
      return of(localAuth0OrgId);
    }

    return this.getTenantId().pipe(
      switchMap((tenantId) => {
        const body = {
          tenant: tenantId,
          topLevelApplication: TOP_LEVEL_APPLICATION,
        };

        return this.fetchAuth0Organization(body);
      }),
      tap((auth0OrgId) => {
        if (auth0OrgId) {
          this.window.localStorage.setItem(
            StorageKeys.Auth0OrganizationId,
            JSON.stringify({ val: auth0OrgId, ttl: getCacheTime() }),
          );
        }
      })
    );
  }

  getTenantId(): Observable<string> {
    const localTenantId = getParsedLocalStorageItemWithTtl<string>(this.window.localStorage.getItem((StorageKeys.TenantId)));

    if (localTenantId) {
      return of(localTenantId);
    }

    return this.fetchDomains().pipe(
      map((domains) => {
        const tenantName = this.window.location.hostname.split('.')[0];
        const tenantId = domains[tenantName] ? domains[tenantName].toUpperCase() : DEFAULT_TENANT_ID;
        this.window.localStorage.setItem(
          StorageKeys.TenantId,
          JSON.stringify({ val: tenantId, ttl: getCacheTime() }),
        );

        return tenantId;
      }),
    );
  }

  fetchDomains(): Observable<{[key: string]: string}> {
    return this.http.get<{[key: string]: string}>(this.brandingUrl);
  }

  fetchAuth0Organization(body): Observable<string> {
    return this.http.post<TlaConfig>(this.tlaUrl, body).pipe(
      map((tlaConfig) => tlaConfig?.auth?.organization),
    );
  }
}
