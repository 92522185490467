import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { WINDOW_TOKEN } from '@app/shared/service/window-token';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  pairwise,
  pluck,
  startWith
} from 'rxjs/operators';
import { AppInitService } from './app-init.service';
import { DOCUMENT_TOKEN } from './document-token';

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {
  private readonly branding$: BehaviorSubject<Branding>;
  private readonly renderer: Renderer2;

  constructor(
    @Inject(WINDOW_TOKEN) private readonly window: Window,
    @Inject(DOCUMENT_TOKEN) private readonly document: Document,
    private readonly configuration: AppInitService,
    rendererFactory: RendererFactory2
  ) {
    // since renderer2 is not yet available
    this.renderer = rendererFactory.createRenderer(null, null);

    this.branding$ = new BehaviorSubject<Branding>(this.defaultBranding());
    this.branding$
      .pipe(startWith({ themeName: 'dark' as ThemeName }), pairwise())
      .subscribe(([prev, next]: Branding[]) => {
        this.changeTheme(
          this.buildThemeClass(prev.themeName),
          this.buildThemeClass(next.themeName)
        );
        this.updateBranding(next);
        this.updateImageUrlsForConfiguration(next);
      });

    this.configuration.settingsInitialized
      .pipe(filter((isInitialized) => isInitialized))
      .subscribe(() => {
        this.branding$.next({ ...this.branding$.value, ...this.configuration.publicConfig?.branding });
      });
  }

  public select<T>(path: string): Observable<T> {
    return this.branding.pipe(pluck<T, T>(...path.split('.')));
  }

  public toggleTheme(): void {
    const branding = this.branding$.value;
    const themeName = branding.themeName === 'dark' ? 'light' : 'dark';

    this.branding$.next({ ...branding, themeName });
  }

  get branding(): Observable<Branding> {
    return this.branding$.asObservable().pipe(distinctUntilChanged());
  }

  private defaultBranding(): Branding {
    const { imageURLs } = this.configuration;

    return {
      themeName: 'dark',
      backgroundImageDesktop: {
        url: imageURLs?.background || 'assets/img/blurry-pond.png'
      },
      backgroundImageMobile: {
        url: imageURLs?.background || 'assets/img/blurry-pond.png'
      },
      logo: {
        url: imageURLs?.logo || 'assets/common/brands-logo-dark.svg'
      }
    };
  }

  private changeTheme(prevTheme: string, nextTheme: string): void {
    if (!nextTheme) {
      return;
    }

    this.renderer.removeClass(this.document.body, prevTheme);
    this.renderer.addClass(this.document.body, nextTheme);
  }

  private updateBranding(branding: Branding): void {
    const themeClass = this.buildThemeClass(branding.themeName);
    const scope = this.buildScope(themeClass);

    this.updateFavicon(branding);
    this.updateColors(scope, branding);
    this.updatePanelCornerRadius(scope, branding);
    this.updateButtonBorderRadius(scope, branding);
    this.updateFonts(scope, branding);
  }

  private updateImageUrlsForConfiguration(branding: Branding): void {
    this.configuration.imageURLs = {
      ...this.configuration.imageURLs,
      background: branding?.backgroundImageDesktop?.url || this.configuration?.imageURLs?.background,
      logo: branding?.logo?.url || this.configuration?.imageURLs?.logo,
      favicon: branding?.favicon?.url || this.configuration?.imageURLs?.favicon
    };
  }

  private buildThemeClass(themeName?: ThemeName): string {
    return `theme-${themeName || 'dark'}`;
  }

  private buildScope(themeClass: string): string {
    return `.${themeClass}`;
  }

  private updateFavicon({ favicon }: Branding): void {
    if (!favicon) {
      return;
    }

    this.renderer.setAttribute(
      this.document.getElementById('appFavicon'),
      'href',
      favicon.url
    );
  }

  private updateColors(
    scope: string,
    { actionColorPrimary, actionColorSecondary }: Branding
  ): void {
    if (!actionColorPrimary) {
      return;
    }

    (this.window as any).colorChanger?.update(
      scope,
      actionColorPrimary,
      actionColorSecondary
    );
  }

  private updatePanelCornerRadius(
    scope: string,
    { panelBorderRadius }: Branding
  ): void {
    if (!panelBorderRadius && panelBorderRadius !== 0) {
      return;
    }

    (this.window as any).panelCornerRadiusChanger?.update(
      scope,
      `${panelBorderRadius}px`
    );
  }

  private updateButtonBorderRadius(
    scope: string,
    { buttonBorderRadius }: Branding
  ): void {
    if (!buttonBorderRadius && buttonBorderRadius !== 0) {
      return;
    }

    (this.window as any).buttonCornerRadiusChanger?.update(
      scope,
      `${buttonBorderRadius}px`
    );
  }

  private updateFonts(scope: string, branding: Branding): void {
    (this.window as any).fontChanger?.update(scope, branding);
  }
}

type ThemeName = 'dark' | 'light';

export interface Branding {
  themeName?: ThemeName;
  actionColorPrimary?: string;
  actionColorSecondary?: string;
  buttonBorderRadius?: number;
  panelBorderRadius?: number;
  logo?: ImageDataModel;
  logoAltText?: string;
  backgroundImageDesktop?: ImageDataModel;
  backgroundImageMobile?: ImageDataModel;
  favicon?: ImageDataModel;
}

interface ImageDataModel {
  url: string;
}
