import { Router } from '@angular/router';
import { Inject, Injectable, NgZone, ErrorHandler } from '@angular/core';
import { AgentConfigOptions } from '@elastic/apm-rum';

import { InjectionToken, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { apm, ApmBase } from '@elastic/apm-rum';

export const APM = new InjectionToken<ApmBase>('APM Base Client');

@Injectable()
export class ApmErrorHandler extends ErrorHandler {
  constructor(@Inject(APM) public apmBase: ApmBase) {
    super();
  }

  handleError(error) {
    this.apmBase.captureError(error.originalError || error);
    super.handleError(error);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApmService {
  constructor(
    @Inject(APM) public apmBase: ApmBase,
    private router: Router,
    private readonly ngZone: NgZone
  ) {}

  init(config: AgentConfigOptions) {
    const apmInstance = this.ngZone.runOutsideAngular(() =>
      this.apmBase.init(config)
    );

    if (!apmInstance.isActive()) {
      return apmInstance;
    }

    /**
     * Start listening to route change once we
     * intiailize to set the correct transaction names
     */
    // this.observe()
    return apmInstance;
  }

  observe() {}
}

@NgModule({
  imports: [RouterModule],
  providers: [{ provide: APM, useValue: apm }]
})
export class ApmModule {}
